import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
	name: "name",
	shortDesc: "shortDesc",
	desc: "desc",
	stock: "stock",
	listPrice: "listPrice",
	image: "image",
	isNewProduct: "isNewProduct",
	isActive: "isActive",
	isFastDelivery: "isFastDelivery",
	category: "category",
	panelName: "panelName",
	listOrderNumber: "listOrderNumber",
	commercialTitle: "commercialTitle",
	name: "name",
	taxNo: "taxNo",
	taxAdministration: "taxAdministration",
	address: "address",
	defaultLanguage: "defaultLanguage",
	phoneNumber: "phoneNumber",
	roomCount: "roomCount",
	drink: "drink",
	isActive: "isActive",
	isOrderLimit: "isOrderLimit",
	commission: "commission",
	orderLimit: "orderLimit",
	serviceCharge: "serviceCharge",
	drinkInvoice: "drinkInvoice",
	foodInvoice: "foodInvoice",
	isOnlineActive: "isOnlineActive",
	isCardActive: "isCardActive",
	isCashActive: "isCashActive",
	branch: "branch",
	users: "users",
	user_email: "user_email",
	user_password: "user_password",
	user_confirm_password: "user_confirm_password",
	user_name: "user_name",
	user_lastName: "user_lastName",
	user_phoneNumber: "user_phoneNumber",
	user_role: "user_role",
	user_status: "user_status",
	user_image: "user_image",
	user_type: "user_type",
	user_isEmailNotificationActive: "user_isEmailNotificationActive",
	user_isPhoneNotificationActive: "user_isPhoneNotificationActive",
	user_isActive: "user_isActive",
	endTime: "endTime",
	startTime: "startTime",
	menus: "menus",
	menu_endTime: "menu_endTime",
	menu_startTime: "menu_startTime",
	menu: "menu",
	currentNotWorkingReason: "currentNotWorkingReason",
	telegramChatId: "telegramChatId",
	name: "name",
	code: "code",
	isActive: "isActive",
	currentNotWorkingReason: "currentNotWorkingReason",
	branches: "branches",
	averageDeliveryTime: "averageDeliveryTime",
	menuCurrency: "menuCurrency",
	currencyMultiplier: "currencyMultiplier",
	days: "days",
	campaign: "campaign",
	isActive: "isActive",
	product: "product",
	integrationCodes: "integrationCodes",
	integrationCode: "integrationCode",
};

const integrationCodes = yup
	.object({
		[fieldNames.integrationCode]: yup.mixed().required(ERRORS.pleaseEnterYour(fieldNames.integrationCode)).label(fieldNames.integrationCode),
		[fieldNames.endTime]: yup.string().label(fieldNames.endTime),
		[fieldNames.startTime]: yup.string().label(fieldNames.startTime),
		[fieldNames.days]: yup.mixed().label(fieldNames.days),
	})
	.defined();
// const menus = yup
// 	.object({
// 		[fieldNames.menu]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.menu)).label(fieldNames.menu),
// 		[fieldNames.endTime]: yup.string().label(fieldNames.endTime),
// 		[fieldNames.startTime]: yup.string().label(fieldNames.startTime),
// 		[fieldNames.days]: yup.mixed().label(fieldNames.days),
// 	})
// 	.defined();

export const validation = (URL_ID) =>
	yup.object().shape({
		[fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.name)).label(fieldNames.name),
		[fieldNames.code]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.code)).label(fieldNames.code),
		[fieldNames.endTime]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.endTime)).label(fieldNames.endTime),
		[fieldNames.startTime]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.startTime)).label(fieldNames.startTime),
		[fieldNames.currencyMultiplier]: yup
			.number()
			.nullable(ERRORS.pleaseEnterYour(fieldNames.currencyMultiplier))
			.min(1, ERRORS.min(1))
			.required(ERRORS.pleaseEnterYour(fieldNames.currencyMultiplier))
			.label(fieldNames.currencyMultiplier),

		[fieldNames.averageDeliveryTime]: yup
			.string()
			.required(ERRORS.pleaseEnterYour(fieldNames.averageDeliveryTime))
			.label(fieldNames.currentNotWorkingReason),
		// [fieldNames.branches]: yup.array().required(ERRORS.pleaseEnterYour(fieldNames.branches)).label(fieldNames.branches),
		// [fieldNames.menus]: yup.array().of(menus).required(ERRORS.pleaseEnterYour(fieldNames.menus)).label(fieldNames.menus),
		[fieldNames.integrationCodes]: yup
			.array()
			.of(integrationCodes)
			.required(ERRORS.pleaseEnterYour(fieldNames.integrationCodes))
			.label(fieldNames.integrationCodes),
		[fieldNames.campaign]: yup
			.object({
				[fieldNames.product]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.product)).label(fieldNames.product),
				[fieldNames.startTime]: yup.string().label(fieldNames.startTime),
				[fieldNames.endTime]: yup.string().label(fieldNames.endTime),
				[fieldNames.isActive]: yup.object().label(fieldNames.isActive),
			})
			.required(ERRORS.pleaseEnterYour(fieldNames.menus)),
	});
