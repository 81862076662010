import configs from "configs";
// import development from "configs/development.json";
// import production from "configs/production.json";

// const environment =
//   process.env.NODE_ENV === "production" ? production : development;

export default {
	host: configs.data.BASE_URL,
	auth: {
		login: "/v1/auth/login",
	},
	refreshToken: "/v1/auth/refresh",
	verify: "/v1/auth/verify-login",
	network: "/v1/network",
	dashboard: "/v1/admin/dashboard",
	priceUpdate: "/v1/admin/products/price-update",

	// user: {
	//   base: "/v1/member/user",
	//   list: "/v1/member/user/list",
	//   get: "/v1/member/user/get",
	//   get: "/v1/member/user/get",
	//   changeStatus: "/v1/member/user/is-active",
	//   current: "/v1/member/user/current",
	// },
	// admin: {
	//   base: "/v1/member/admin",
	//   list: "/v1/member/admin/list",
	//   get: "/v1/member/admin/get",
	//   get: "/v1/member/admin/get",
	//   changeStatus: "/v1/member/admin/is-active",
	//   current: "/v1/member/admin/current",
	// },
	integrationCodes: {
		base: "/v1/admin/integration-codes",
		list: "/v1/admin/integration-codes",
		get: "/v1/admin/integration-codes",
		delete: "/v1/admin/integration-codes",
		changeStatus: "/v1/admin/integration-codes/is-active",
	},
	ideaBox: {
		base: "/v1/admin/idea-boxes",
		list: "/v1/admin/idea-boxes",
		get: "/v1/admin/idea-boxes",
		delete: "/v1/admin/idea-boxes",
		changeStatus: "/v1/admin/idea-boxes/is-active",
	},
	invoice: {
		base: "/v1/admin/finance/invoice",
		list: "/v1/admin/finance/invoice",
		get: "/v1/admin/finance/invoice",
		delete: "/v1/admin/finance/invoice",
		changeStatus: "/v1/admin/finance/invoice/is-active",
	},
	multiplePrice: {
		base: "/v1/admin/hotels/multiple-price-update",
		list: "/v1/admin/hotels/multiple-price-update",
		get: "/v1/admin/hotels/multiple-price-update",
		delete: "/v1/admin/hotels/multiple-price-update",
		changeStatus: "/v1/admin/hotels/multiple-price-update/is-active",
	},
	finance: {
		base: "/v1/admin/dashboard/finance",
		list: "/v1/admin/dashboard/finance",
		get: "/v1/admin/dashboard/finance",
		show: "/v1/admin/dashboard/finance/hotel",
		delete: "/v1/admin/dashboard/finance",
		changeStatus: "/v1/admin/dashboard/finance/is-active",
	},
	financeHotels: {
		base: "/v1/admin/finance/hotels",
		show: "/v1/admin/finance/hotels",
		list: "/v1/admin/finance/hotels",
		get: "/v1/admin/finance/hotels",
		delete: "/v1/admin/finance/hotels",
		changeStatus: "/v1/admin/finance/hotels/is-active",
	},
	financeBranches: {
		base: "/v1/admin/finance/branches",
		list: "/v1/admin/finance/branches",
		get: "/v1/admin/finance/branches",
		delete: "/v1/admin/finance/branches",
		changeStatus: "/v1/admin/finance/branches/is-active",
	},
	user: {
		base: "/v1/admin/users",
		list: "/v1/admin/users",
		get: "/v1/admin/users",
		delete: "/v1/admin/users",
		changeStatus: "/v1/admin/users/is-active",
		current: "/v1/users/current",
		profile: "/v1/admin/dashboard/profile",
	},
	order: {
		base: "/v1/admin/orders",
		list: "/v1/admin/orders",
		get: "/v1/admin/orders",
		delete: "/v1/admin/orders",
		// statistics: "/v1/admin/orders/statistics",
		statistics: {
			v1: "/v1/admin/statistics",
			v2: "/v2/admin/statistics",
			hotel: "/v1/admin/statistics/hotels",
			room: "/v1/admin/statistics/rooms",
			status: "/v1/admin/orders/order-status-statistics",
		},
		changeStatus: "/v1/admin/orders/is-active",
		products: "/v1/admin/orders/products",
		fullData: "/v1/admin/orders/order-statistics",
		deliveryReport: "/v1/admin/orders/order-delivery-statistics",
	},
	hotel: {
		base: "/v1/admin/hotels",
		list: "/v1/admin/hotels",
		get: "/v1/admin/hotels",
		delete: "/v1/admin/hotels",
		changeStatus: "/v1/admin/hotels/is-active",
	},
	discount: {
		base: "/v1/admin/discounts",
		list: "/v1/admin/discounts",
		get: "/v1/admin/discounts",
		delete: "/v1/admin/discounts",
		changeStatus: "/v1/admin/discounts/is-active",
	},
	contactUs: {
		base: "/v1/admin/contact-us",
		list: "/v1/admin/contact-us",
		get: "/v1/admin/contact-us",
		delete: "/v1/admin/contact-us",
		changeStatus: "/v1/admin/contact-us/is-active",
	},
	menu: {
		base: "/v1/admin/hotels/menu",
		list: "/v1/admin/hotels/menu",
		get: "/v1/admin/hotels/menu",
		delete: "/v1/admin/hotels/menu",
		copy: "/v1/admin/hotels/menu/copy-menu",
		addAllItem: "/v1/admin/menus/add-item-all-menus",
	},
	role: {
		base: "/v1/admin/users/roles",
		list: "/v1/admin/users/roles",
		get: "/v1/admin/users/roles",
		delete: "/v1/admin/users/roles",
	},
	setting: {
		base: "/v1/admin/settings",
		list: "/v1/admin/settings",
		get: "/v1/admin/settings",
		delete: "/v1/admin/settings",
		changeStatus: "/v1/admin/settings/is-active",
	},
	language: {
		base: "/v1/admin/languages",
		list: "/v1/admin/languages",
		get: "/v1/admin/languages",
		delete: "/v1/admin/languages",
		changeStatus: "/v1/admin/languages/is-active",
	},
	region: {
		base: "/v1/admin/regions",
		list: "/v1/admin/regions",
		get: "/v1/admin/regions",
		delete: "/v1/admin/regions",
		changeStatus: "/v1/admin/regions/is-active",
	},
	branch: {
		base: "/v1/admin/branches",
		list: "/v1/admin/branches",
		get: "/v1/admin/branches",
		delete: "/v1/admin/branches",
		changeStatus: "/v1/admin/branches/is-active",
	},
	product: {
		base: "/v1/admin/products",
		list: "/v1/admin/products",
		get: "/v1/admin/products",
		delete: "/v1/admin/products",
		changeStatus: "/v1/admin/products/is-active",
	},
	PendingPriceUpdate: {
		base: "/v1/admin/products",
		list: "/v1/admin/products/pending-product-updates",
		add: "/v1/admin/products/branch-buy-price-update",
		reject: "/v1/admin/pending-product-updates",
		changeStatus: "/v1/admin/products/is-active",
	},
	report: {
		base: "/v1/admin/reports/hourly",
		list: "/v1/admin/reports/hourly",
		get: "/v1/admin/reports/hourly",
		delete: "/v1/admin/reports/hourly",
		changeStatus: "/v1/admin/reports/hourly/is-active",
	},
	hourlyReport: {
		base: "/v1/admin/reports/hourly",
		list: "/v1/admin/reports/hourly",
		get: "/v1/admin/reports/hourly",
		delete: "/v1/admin/reports/hourly",
		changeStatus: "/v1/admin/reports/hourly/is-active",
	},
	costReport: {
		base: "/v1/admin/reports/cost",
		list: "/v1/admin/reports/cost",
		get: "/v1/admin/reports/cost",
		delete: "/v1/admin/reports/cost",
		changeStatus: "/v1/admin/reports/cost/is-active",
	},
	languageReport: {
		base: "/v1/admin/reports/language",
		list: "/v1/admin/reports/language",
		get: "/v1/admin/reports/language",
		delete: "/v1/admin/reports/language",
		changeStatus: "/v1/admin/reports/language/is-active",
	},
	hour: {
		base: "/v1/admin/hour",
		list: "/v1/admin/hour",
		get: "/v1/admin/hour",
		delete: "/v1/admin/hour",
	},
	deliveries: {
		base: "/v1/admin/deliveries",
		list: "/v1/admin/deliveries",
		get: "/v1/admin/deliveries",
		delete: "/v1/admin/deliveries",
	},
	menus: {
		base: "/v1/admin/menus",
		list: "/v1/admin/menus",
		get: "/v1/admin/menus",
		delete: "/v1/admin/menus",
	},
	activities: {
		base: "/v1/admin/activities",
		list: "/v1/admin/activities",
		get: "/v1/admin/activities",
		delete: "/v1/admin/activities",
	},
	places: {
		base: "/v1/admin/places",
		list: "/v1/admin/places",
		get: "/v1/admin/places",
		delete: "/v1/admin/places",
	},
	newsletter: {
		base: "/v1/newsletter",
		list: "/v1/newsletter/list",
		get: "/v1/newsletter/get",
		delete: "/v1/newsletter/get",
		changeStatus: "/v1/newsletter/is-active",
	},
	permission: {
		base: "/v1/admin/users/permissions",
		list: "/v1/admin/users/permissions",
		default: "/v1/admin/users/permissions/default",
		get: "/v1/admin/users/permissions",
		delete: "/v1/admin/users/permissions",
		userType: `/v1/admin/users/permissions/user-type`,
	},
	// ------------------------------------------------- blogs
	category: {
		base: "/v1/admin/product-categories",
		list: "/v1/admin/product-categories",
		get: "/v1/admin/product-categories",
		delete: "/v1/admin/product-categories",
	},
	tag: {
		base: "/v1/blog/tag",
		list: "/v1/blog/tag/list",
		get: "/v1/blog/tag/get",
		delete: "/v1/blog/tag/get",
		changeStatus: "/v1/blog/tag/is-active",
	},
	post: {
		base: "/v1/blog/post",
		list: "/v1/blog/post/list",
		get: "/v1/blog/post/get",
		delete: "/v1/blog/post/get",
		changeStatus: "/v1/blog/post/is-active",
	},
	// ------------------------------------------------- media
	folder: {
		base: "/v1/media/folder",
		list: "/v1/media/folder/list",
		get: "/v1/media/folder/get",
		delete: "/v1/media/folder/get",
		getBySlug: "/v1/media/folder/get/slug",
		changeStatus: "/v1/media/folder/is-active",
	},
	gallery: {
		base: "/v1/admin/files",
		list: "/v1/admin/files",
		get: "/v1/admin/files",
		delete: "/v1/admin/files",
		changeStatus: "/v1/admin/files",
	},
};
