import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { Children, useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";

import FieldSelect from "components/FieldSelect";
import FieldText from "components/FieldText";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import cLog from "utils/cLog";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";
import { DRINK_DATA, INVOICE_DATA, PAYMENT_PERIOD, PAYMENT_PERIOD_OBJECT, ROLES, STATUSES } from "enumerations";
import GalleryModal from "components/gallery/GalleryModal";
import CheckBox from "components/CheckBox";
import moment from "moment";
import FieldDate from "components/FieldDate";
// import GalleryComponent from "components/gallery/GalleryComponent";
// import { convertToLabelValue } from "utils/convertToLabelValue";
import { useTranslation } from "react-i18next";
import MenuSelector, { convertMenuData } from "../../Menus/MenuSelector";

const Add = () => {
	const [showPass, setShowPass] = useState(false);
	const [showConfirmPass, setShowConfirmPass] = useState(false);
	const [type, setType] = useState(null);
	const { t } = useTranslation();
	const location = useLocation();

	// ------------------------------------ route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	const previousQuery = location?.state?.query;

	const typeOptions = [
		{ label: "User", value: "USER" },
		{ label: "Admin", value: "ADMIN" },
	];

	// ----------------------------------------------------------------------------- Fetching Functions
	const creating = async (params) => await axiosClient().post(api[API_NAME].base, params);
	const updating = async (params) => await axiosClient().put(api[API_NAME].base + "/" + URL_ID, params);
	const getById = async () => await axiosClient().get(api[API_NAME].get + "/" + URL_ID);
	// const getRoles = async () => await axiosClient().get(api["role"].list);

	const navigation = useNavigate();
	let { id } = useParams();
	const URL_ID = id ? id : "";
	// ----------------------------------------------------------------------------- Form
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
		control,
		reset,
	} = useForm({
		resolver: yupResolver(validation(URL_ID)),
		mode: "onSubmit",
	});

	// ----------------------------------------------------------------------------- Mutation
	const onSuccessMutating = () => {
		toastify.success({ title: "success" });
		// navigation(newRoute.base);
		const params = new URLSearchParams(previousQuery || {});
		navigation(newRoute.base + newRoute.list + `?${params.toString()}`);
	};
	const { isLoading, data, mutate } = useMutationCustom({
		url: URL_ID ? updating : creating,
		invalidQuery: `getById_${API_NAME}`,
		onSuccess: onSuccessMutating,
	});

	// ----------------------------------------------------------------------------- Query By ID
	const onSuccessDataById = ({ data }) => {
		let params = { ...data };
		let selectedStatus = STATUSES.find((item) => item.value === params.isActive);
		let date = "2017-03-13";
		console.log({ params });
		const resData = {
			...params,
			isActive: selectedStatus,
			user_email: params?.user?.email,
			user_id: params?.user?.id,
			user_isActive: STATUSES.find((item) => item.value === params?.user?.isActive),
			user_isEmailNotificationActive: params?.user?.isEmailNotificationActive,
			user_isPhoneNotificationActive: params?.user?.isPhoneNotificationActive,
			user_lastName: params?.user?.lastName,
			user_name: params?.user?.name,
			user_phoneNumber: params?.user?.phoneNumber,
			// startTime: new Date(moment(date + " " + params?.startTime).format()),
			// endTime: new Date(moment(date + " " + params?.endTime).format()),
			menus: params?.menus?.map((x) => ({
				menu_menu: convertMenuData(x.menu),
				menu_startTime: x.startTime,
				menu_endTime: x.endTime,
			})),
			paymentPeriod: PAYMENT_PERIOD_OBJECT[params?.paymentPeriod],
		};
		console.log("resData ===>", resData);
		// handleSelectThumbnail({ _id: params?.image?._id, completedUrl: params?.image?.completedUrl, title: params?.image?.title });
		reset(resData);
	};
	const dataById = useQueryCustom({
		name: `getById_${API_NAME}`,
		url: getById,
		onSuccess: onSuccessDataById,
		enabled: !!URL_ID,
	});

	//  ----------------------------------------- GETTING ROLES ----------------------------------------- //
	// const onSuccessRoles = ({ data }) => {
	//   setRolesOption(convertToLabelValue(data?.result));
	// };
	// const rolesData = useQueryCustom({
	//   name: "rolesData",
	//   url: getRoles,
	//   onSuccess: onSuccessRoles,
	//   params: { sort: "desc", page: 1, limit: 10, status: 1 },
	// });
	// --------------------------------------- Submit Form
	const onSubmit = () => {
		const {
			user_email,
			user_isEmailNotificationActive,
			user_isPhoneNotificationActive,
			user_lastName,
			user_password,
			user_name,
			user_phoneNumber,
			user_isActive,
			endTime,
			startTime,
			menus,
			...values
		} = getValues();
		const requestData = {
			...values,
			paymentPeriod: values?.paymentPeriod?.value,
			isActive: values.isActive ? values.isActive.value : "1",
			networkDry: !!values.networkDry,
			// startTime: moment(startTime).locale("en").format("hh:mm A"),
			// endTime: moment(endTime).locale("en").format("hh:mm A"),
			// menus: menus?.map((x) => ({
			//   menu: x.menu_menu.value,
			//   startTime: x.menu_startTime,
			//   endTime: x.menu_endTime,
			// })),

			user: {
				email: user_email,
				isActive: STATUSES.find((item) => item.value === user_isActive?.value)?.value,
				isEmailNotificationActive: !!user_isEmailNotificationActive,
				isPhoneNotificationActive: !!user_isPhoneNotificationActive,
				...(user_password && { password: user_password }),
				lastName: user_lastName,
				name: user_name,
				phoneNumber: user_phoneNumber,
				...(URL_ID && { id: dataById?.data?.data?.user?.id }),
			},
			// image: values.image._id,
		};
		// image: values.image._id,
		console.log("requestData ===>", { requestData });
		console.log("values", values);
		mutate(requestData);
	};

	// ----------------------------------------------------------------------------- Constance
	const dispatch = useDispatch();
	const button = {
		title: `Back To ${COMPONENT_NAMES[0]} List`,
		link: newRoute.base + newRoute.list,
	};

	const breadcrumbs = [
		{ title: "Dashboard", link: routes.dashboard.base },
		{ title: COMPONENT_NAMES[0], link: newRoute.base },
		{
			title: URL_ID ? `Edit / ${dataById?.data?.data?.name}` : "Add",
		},
	];
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, [dataById]);
	// ----------------------------------------------------------------------------- Log
	const values = getValues();
	console.log({ dataById, values }, "Add");
	console.log({ URL_ID });
	console.log({ errors }, "errors");

	// ----------------------------------------------------------------------------- UseEffects

	//  ----------------------------------------- GALLERY ----------------------------------------- //
	const [showModal, setShowModal] = useState(false);
	const [thumbnail, setThumbnail] = useState(null);
	const handleGalleryModal = () => {
		setShowModal(!showModal);
	};
	const handleSelectThumbnail = (thumbnail) => {
		setValue(fieldNames.image, thumbnail, {
			shouldValidate: true,
		});
		setThumbnail(thumbnail);
	};

	const defaultData = { user_name: "" };

	const addNewUser = () => {
		const values = getValues();
		const newData = values[fieldNames.users] ? [...values[fieldNames.users], { ...defaultData }] : [{ ...defaultData }, { ...defaultData }];
		console.log({ newData });
		setValue(fieldNames.users, newData);
		// reset({ ...values, [fieldNames.locale]: values[fieldNames.locale] ? [...values[fieldNames.locale], { ...defaultEpisode }] : [{ ...defaultEpisode }, { ...defaultEpisode }] })
	};
	const addNewMenu = () => {
		const values = getValues();
		const newData = values[fieldNames.menus] ? [...values[fieldNames.menus], { ...defaultData }] : [{ ...defaultData }, { ...defaultData }];
		console.log({ newData });
		setValue(fieldNames.menus, newData);
	};
	return (
		<div>
			<GalleryModal
				{...{
					showModal,
					handleModal: handleGalleryModal,
					handleClickedImage: handleSelectThumbnail,
					selectedPhoto: thumbnail,
				}}
			/>
			<PageTitle title={URL_ID ? `Edit  ${COMPONENT_NAMES[0]}` : `Add  ${COMPONENT_NAMES[0]}`} button={button} />
			<form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
				<div className="row mt-5">
					<Col md="8">
						<div className="col-12 border p-4">
							<div className="">
								<div className="b-1">
									<h4 className="">{"Basic Information"}</h4>
								</div>
								<div className="mt-5">
									<div className="col-12">
										<div className="basic-form">
											<FieldText
												require
												{...{
													control,
													name: fieldNames.name,
													register,
													placeholder: "name",
													label: "name",
													errors,
												}}
											/>
											<FieldText
												require
												{...{
													control,
													name: fieldNames.panelName,
													register,
													placeholder: "panelName",
													label: "panelName",
													errors,
												}}
											/>

											<FieldText
												require
												{...{
													control,

													name: fieldNames.commercialTitle,
													register,
													placeholder: "commercialTitle",
													label: "commercialTitle",
													errors,
												}}
											/>
											<FieldText
												require
												{...{
													control,

													name: fieldNames.taxNo,
													register,
													placeholder: "taxNo",
													label: "taxNo",
													errors,
												}}
											/>

											<FieldText
												require
												{...{
													control,

													name: fieldNames.taxAdministration,
													register,
													placeholder: "taxAdministration",
													label: "taxAdministration",
													errors,
												}}
											/>

											<FieldText
												require
												{...{
													control,

													name: fieldNames.address,
													register,
													placeholder: "address",
													label: "address",
													errors,
												}}
											/>

											{/* <FieldDate
                        require
                        {...{
                          control,
                          name: fieldNames.startTime,
                          register,
                          placeholder: " 11:20 am",
                          label: "startTime",
                          errors,
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={5}
                        timeCaption="Time"
                       dateFormat="HH:mm"
                        showTimeInput={false}
                      />

                      <FieldDate
                        require
                        {...{
                          control,
                          name: fieldNames.endTime,
                          register,
                          placeholder: "endTime",
                          label: "endTime ",
                          errors,
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={5}
                        timeCaption="Time"
                       dateFormat="HH:mm"
                        showTimeInput={false}
                      /> */}

											{/* <FieldText
                        require
                        {...{
                          control,

                          name: fieldNames.currentNotWorkingReason,
                          register,
                          placeholder: "currentNotWorkingReason",
                          label: "currentNotWorkingReason",
                          errors,
                        }}
                      />       */}

											<FieldSelect
												{...{
													name: fieldNames.paymentPeriod,
													label: "payment period",
													// value: value?.[index]?.[fieldNames.user_isActive],
													errors: errors,
													register,
													control,
													options: PAYMENT_PERIOD,
												}}
											/>

											<FieldSelect
												{...{
													Controller,
													name: fieldNames.isActive,
													register,
													label: "Status",
													errors,
													control,
													options: STATUSES,
												}}
											/>

											<CheckBox
												{...{
													name: fieldNames.networkDry,
													label: "networkDry",
													// value: value?.[index]?.[fieldNames.user_isActive],
													errors: errors,
													register,
													control,
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* // menuuuu */}
						{/* <div className="col-12 border p-4 mt-4">
              <div className="">
              
                <Row className="b-1">
                  <Col lg={6}>
                    <h2 className="">Menu</h2>
                  </Col>
                  <Col lg={6} className="d-flex justify-content-end"></Col>
                </Row>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form"></div>

                    <Row className="b-1">
                      <Col lg={6}>
                        <h2 className="">Menus</h2>
                      </Col>
                      <Col lg={6} className="d-flex justify-content-end">
                        <Button onClick={addNewMenu} className={"d-flex align-items-center"} size="sm">
                          <div className={"gallery-add-title"}>Add Menu</div>
                          <div className={"ml-1"}>+</div>
                        </Button>
                      </Col>
                    </Row>
                    <div className="mt-5">
                      <div className="col-12">
                        <div className="basic-form"></div>
                        <Controller
                          name={fieldNames.menus}
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            console.log({ value });

                            return Children.toArray(
                              (value || [{ ...defaultData }]).map((locale, index) => {
                                const values2 = getValues();

                                function changeHandle(param) {
                                  console.log({ param });
                                  let values = value ? [...value] : [{ ...defaultData }];
                                  values[index][param.name] = param.value;
                                  onChange(values);
                                }

                                // function languageSelectHandler(e) {
                                //   changeHandle({ name: "isFree", value: e });
                                //   // console.log({ select: params.target.value });
                                //   console.log({ e });
                                // }

                                // function reasonSelectHandler(e) {
                                //   let target = e.target;
                                //   changeHandle({ name: target.name, value: target.value });
                                // }
                                // function datePickerHandler(e) {
                                //   changeHandle({ name: "startDate", value: e });
                                //   let date = new Date(e.toISOString());
                                //   // setStartDate(date);
                                // }

                                function handleChangeInput(e) {
                                  let target = e.target;
                                  console.log({ target });
                                  changeHandle({ name: target.name, value: target.checked || target.value });
                                }
                                function handleChangeSelect(e) {
                                  // let target = e.target;
                                  console.log({ e });
                                  changeHandle({ name: e.name, value: { label: e.label, value: e.value } });
                                }
                                function handleChangeCheckbox(e) {
                                  console.log({ e });
                                  changeHandle({ name: e.name, value: e.checked || e.value });
                                }

                                const removeClick = (index) => {
                                  let values = getValues();
                                  if (!values[fieldNames.menus]) return;
                                  const newValues = values[fieldNames.menus].filter((param, i) => i !== index);
                                  setValue(fieldNames.menus, newValues);
                                };

                                return (
                                  <Row className="items-row" key={value?.translate_email || index}>
                                    {index ? (
                                      <>
                                        <Col lg={10}>
                                          <h5>Item{index + 1}</h5>
                                        </Col>
                                        <Col lg={2}>
                                          <div>
                                            <Button
                                              color="warning"
                                              className={"d-flex align-items-center"}
                                              size="sm"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                removeClick(index);
                                              }}
                                            >
                                              remove
                                            </Button>
                                          </div>
                                        </Col>
                                        <Col lg={12}>
                                          <hr style={{ marginBottom: 0 }} />
                                        </Col>
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    <MenuSelector
                                      {...{
                                        Controller,
                                        name: fieldNames.menu_menu,
                                        register,
                                        label: "menu",
                                        errors: errors.items?.[index],
                                        control,
                                        value: value?.[index]?.[fieldNames.menu_menu],
                                        onChange: (e) => handleChangeSelect({ ...e, name: fieldNames.menu_menu }),
                                      }}
                                    />
                                 
                                    <FieldText
                                      require
                                      {...{
                                        name: fieldNames.menu_startTime,
                                        placeholder: "startTime",
                                        label: "startTime ",
                                        value: value?.[index]?.[fieldNames.menu_startTime],
                                        errors: errors.items?.[index],
                                        onChange: handleChangeInput,
                                      }}
                                    />
                                    <FieldText
                                      require
                                      {...{
                                        name: fieldNames.menu_endTime,
                                        placeholder: "endTime",
                                        label: "endTime ",
                                        value: value?.[index]?.[fieldNames.menu_endTime],
                                        errors: errors.items?.[index],
                                        onChange: handleChangeInput,
                                      }}
                                    />
                                  </Row>
                                );
                              })
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
						<div className="col-12 border p-4 mt-4">
							<div className="">
								{/* <div className="b-1">
                  <h4 className="">{"users"}</h4>

                </div> */}
								<Row className="b-1">
									<Col lg={6}>
										<h2 className="">Users</h2>
									</Col>
									<Col lg={6} className="d-flex justify-content-end"></Col>
								</Row>
								<div className="mt-5">
									<div className="col-12">
										<div className="basic-form"></div>

										<Row className="user-row">
											<FieldText
												require
												{...{
													name: fieldNames.user_name,
													placeholder: "firstName",
													label: "firstName ",
													// errors: errors.users?.[index],
													// value: value?.[index]?.[fieldNames.user_name],
													errors: errors,
													register,
													control,
													// onChange: handleChangeInput
												}}
											/>
											<FieldText
												require
												{...{
													name: fieldNames.user_lastName,
													placeholder: "last name",
													label: "Last Name ",
													// value: value?.[index]?.[fieldNames.user_lastName],
													errors: errors,
													register,
													control,
													// errors: errors.users?.[index],
													// onChange: handleChangeInput
												}}
											/>
											<FieldText
												require
												{...{
													name: fieldNames.user_email,
													placeholder: "email",
													label: "Email ",
													// value: value?.[index]?.[fieldNames.user_email],
													errors: errors,
													register,
													control,
													// errors: errors.users?.[index],
													// onChange: handleChangeInput
												}}
											/>
											<FieldText
												{...{
													name: fieldNames.user_phoneNumber,
													placeholder: "Mobile Number",
													label: "Mobile Number ",
													// value: value?.[index]?.[fieldNames.user_phoneNumber],
													errors: errors,
													register,
													control,
													// errors: errors.users?.[index],
													// onChange: handleChangeInput
												}}
											/>
											<FieldText
												require
												{...{
													// disabled: URL_ID,
													name: fieldNames.user_password,
													placeholder: "password",
													label: "Password",
													// value: value?.[index]?.[fieldNames.user_password],
													errors: errors,
													register,
													control,
													// onChange: handleChangeInput
												}}
											/>
											<FieldText
												require
												{...{
													// disabled: URL_ID,
													name: fieldNames.user_confirm_password,
													placeholder: "Confirm Password",
													label: "Confirm Password",
													// value: value?.[index]?.[fieldNames.user_confirm_password],
													errors: errors,
													register,
													control,
												}}
											/>

											<FieldSelect
												{...{
													name: fieldNames.user_isActive,
													label: "Status",
													// value: value?.[index]?.[fieldNames.user_isActive],
													errors: errors,
													register,
													control,
													options: STATUSES,
												}}
											/>
											<CheckBox
												{...{
													name: fieldNames.user_isEmailNotificationActive,
													label: "is Email Notification Active",
													// value: value?.[index]?.[fieldNames.user_isEmailNotificationActive],
													// errors: errors.users?.[index],
													// onChange: handleChangeInput,
													errors: errors,
													register,
													control,
												}}
											/>
											<CheckBox
												{...{
													name: fieldNames.user_isPhoneNotificationActive,
													label: "is Phone Notification Active",
													// value: value?.[index]?.[fieldNames.user_isPhoneNotificationActive],
													errors: errors,
													// onChange: handleChangeInput,
													register,
													control,
												}}
											/>
										</Row>
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col md="4">
						<div className="row">
							<Col md="12 border p-4">
								<div className="card">
									<div className="">
										<h4 className="card-title">{"Actions"}</h4>
									</div>

									<div className="card-body">
										<div className="basic-form">
											{/* <div className='publishing-actions'>
                        <div className='pub-section'>
                          <i className="fa glyphicon glyphicon-export fa-info-circle mr-2"></i>
                          <span>Status : </span>
                          <span className='text-success ml-2'> Active</span>
                        </div>
                      </div> */}

											{/* <hr /> */}

											<Button block type="success" htmlType="submit" className={`btn btn-success btn-sm`} loading={isLoading}>
												{!isLoading ? t("publish") : "Loading..."}
											</Button>
										</div>
									</div>
								</div>
							</Col>
						</div>

						{/* <div className="row mt-4">
              <Col md="12 border ">
                <div className="card">
                  <div className="p-4">
                    <h4 className="card-title">{"Select data"}</h4>
                  </div>

                  <div className="card-body">
                    <div className="basic-form">
                      <FieldSelect
                        {...{
                          // Controller,
                          name: fieldNames.drinkInvoice,
                          register,
                          label: "drinkInvoice",
                          errors,
                          control,
                          options: INVOICE_DATA,
                        }}
                      />

                      <FieldSelect
                        {...{
                          // Controller,
                          name: fieldNames.foodInvoice,
                          register,
                          label: "foodInvoice",
                          errors,
                          control,
                          options: INVOICE_DATA,
                        }}
                      />

                      <FieldSelect
                        {...{
                          // Controller,
                          name: fieldNames.users,
                          register,
                          label: "users",
                          errors,
                          control,
                          options: [],
                        }}
                      />


                      <FieldSelect
                        {...{
                          // Controller,
                          name: fieldNames.drink,
                          register,
                          label: "drink",
                          errors,
                          control,
                          options: DRINK_DATA,
                        }}
                      />





                      <FieldSelect
                        {...{
                          // Controller,
                          name: fieldNames.branch,
                          register,
                          label: "branch",
                          errors,
                          control,
                          options: [],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </div> */}
					</Col>
				</div>
			</form>
		</div>
	);
};

export default Add;

// <Controller control={control} name={fieldNames.status} render={
//     ({ field: { onChange, value } }) => {
//         let valid = value !== undefined
//         return <Dropdown>
//             <Dropdown.Toggle
//                 variant="outline-primary"
//                 size="sm"
//                 className="mt-1 mb-2"
//             >
//                 {valid ? value ? 'Active' : 'Disable' : 'Status'}
//             </Dropdown.Toggle>
//             <Dropdown.Menu>
//                 <Dropdown.Item onClick={() => onChange(true)} active={value}>Active</Dropdown.Item>
//                 <Dropdown.Item onClick={() => onChange(false)} active={!value}>Disable</Dropdown.Item>
//             </Dropdown.Menu>
//         </Dropdown>
//     }
// } >

// </Controller>
