const routes = {
	home: `/home`,
	login: `/login`,
	verify: `/verify`,
	dashboard: "/dashboard",
	dashboard: {
		name: "dashboard",
		base: "/dashboard",
	},
	file: {
		name: "files",
		base: `/file`,
		list: `/file/list`,
		add: `/file/add`,
		edit: `/file/edit`,
	},
	role: {
		name: "roles",
		all: "/role/*",
		base: `/role`,
		list: `/list`,
		add: `/add`,
		edit: `/edit`,
	},
	report: {
		name: "reports",
		all: "/report/*",
		base: `/report`,
		list: `/list`,
		add: `/add`,
		edit: `/edit`,
	},
	hourlyReport: {
		name: "hourlyReports",
		all: "/hourly-report/*",
		base: `/hourly-report`,
		list: `/list`,
		add: `/add`,
		edit: `/edit`,
	},
	costReport: {
		name: "costReports",
		all: "/cost-report/*",
		base: `/cost-report`,
		list: `/list`,
		add: `/add`,
		edit: `/edit`,
	},
	languageReport: {
		name: "languageReports",
		all: "/language-report/*",
		base: `/language-report`,
		list: `/list`,
		add: `/add`,
		edit: `/edit`,
	},
	discount: {
		name: "discounts",
		all: "/discount/*",
		base: `/discount`,
		list: `/list`,
		add: `/add`,
		edit: `/edit`,
	},
	ideaBox: {
		name: "idea-boxes",
		all: "/idea-box/*",
		base: `/idea-box`,
		list: `/list`,
		add: `/add`,
		edit: `/edit`,
	},
	permission: {
		name: "permissions",
		all: "/permission/*",
		base: `/permission`,
		list: `/list`,
		add: `/add`,
		edit: `/edit`,
	},
	integrationCodes: {
		name: "integrationCode",
		all: "/integrationCode/*",
		base: `/integrationCode`,
		list: `/list`,
		add: `/add`,
		edit: `/edit`,
	},
	user: {
		name: "users",
		base: "/user",
		all: "/user/*",
		list: "/list",
		add: "/add",
		edit: "/edit",
		inactive: "edit/inactive",
		active: "edit/active",
		kyc: {
			base: "/user/kyc",
			list: "/user/kyc/list",
			add: "/user/kyc/add",
			edit: "/user/kyc/edit",
		},
		device: {
			base: "/user/device",
			list: "/user/device/list",
			add: "/user/device/add",
			edit: "/user/device/edit",
		},
		level: {
			base: "/user/level",
			list: "/user/level/list",
			add: "/user/level/add",
			edit: "/user/level/edit",
		},
	},

	multiplePrice: {
		name: "mpu",
		base: "/multiple-price",
		all: "/multiple-price/*",
		list: "/list",
		add: "/add",
	},

	finance: {
		name: "finance",
		base: "/finance",
		all: "/finance/*",
		list: "/list",
		show: "/show",
	},
	invoice: {
		name: "invoice",
		base: "/finance/invoice",
		all: "/finance/invoice/*",
		list: "/list",
		show: "/show",
	},
	financeBranches: {
		name: "financeBranches",
		base: "/finance/branches",
		all: "/finance/branches/*",
		list: "/list",
		show: "/show",
	},
	financeHotels: {
		name: "financeHotels",
		base: "/finance/hotels",
		all: "/finance/hotels/*",
		list: "/list",
		show: "/show",
	},
	language: {
		name: "languages",
		base: "/language",
		all: "/language/*",
		list: "/list",
		add: "/add",
		edit: "/edit",
	},
	contactUs: {
		name: "contact-us",
		base: "/contact-us",
		all: "/contact-us/*",
		list: "/list",
		add: "/add",
		edit: "/edit",
	},
	order: {
		name: "orders",
		base: "/order",
		all: "/order/*",
		list: "/list",
		add: "/add",
		edit: "/edit",
	},
	setting: {
		name: "settings",
		base: "/setting",
		all: "/setting/*",
		list: "/list",
		add: "/add",
		edit: "/edit",
	},

	hotel: {
		name: "hotels",
		base: "/hotel",
		all: "/hotel/*",
		list: "/list",
		add: "/add",
		edit: "/edit",
		user: {
			base: "/hotel/user",
			all: "/user/*",
			list: "/list",
			add: "/add",
			edit: "/edit",
			w: {
				base: "/user",
				list: "/list",
				add: "/add",
				edit: "/edit",
			},
		},
		menu: {
			base: "/hotel/menu",
			all: "/menu/*",
			list: "/list",
			add: "/add",
			edit: "/edit",
			copy: "/copy-menu",
			w: {
				base: "/menu",
				list: "/list",
				add: "/add",
				edit: "/edit",
				copy: "/copy-menu",
			},
		},
	},

	branch: {
		name: "branches",
		base: "/branch",
		all: "/branch/*",
		list: "/list",
		add: "/add",
		edit: "/edit",
	},
	region: {
		name: "regions",
		base: "/region",
		all: "/region/*",
		list: "/list",
		add: "/add",
		edit: "/edit",
	},

	product: {
		name: "products",
		base: "/product",
		all: "/product/*",
		list: "/list",
		add: "/add",
		edit: "/edit",
	},
	PendingPriceUpdate: {
		name: "pendingProduct",
		base: "/pending-product-update",
		all: "/pending-product-update/*",
		list: "/list",
		add: "/add",
		edit: "/edit",
	},
	hour: {
		name: "hours",
		base: "/hour",
		all: "/hour/*",
		list: "/list",
		add: "/add",
		edit: "/edit",
	},
	activities: {
		name: "activities",
		base: "/activities",
		all: "/activities/*",
		list: "/list",
		add: "/add",
		edit: "/edit",
	},
	deliveries: {
		name: "deliveries",
		base: "/deliveries",
		all: "/deliveries/*",
		list: "/list",
		add: "/add",
		edit: "/edit",
	},
	menus: {
		name: "menus",
		base: "/menus",
		all: "/menus/*",
		list: "/list",
		add: "/add",
		addItem: "/add-item",
		addItemsToMenus: "/add-items-to-menus",
		edit: "/edit",
	},
	places: {
		name: "places",
		base: "/places",
		all: "/places/*",
		list: "/list",
		add: "/add",
		edit: "/edit",
	},
	gallery: {
		base: "/gallery",
		all: "/gallery/*",
		list: "/list",
		add: "/add",
		edit: "/edit",
	},
	category: {
		name: "productCategories",
		base: "/category",
		all: "/category/*",
		list: "/list",
		add: "/add",
		edit: "/edit",
	},
	newsletter: {
		base: "/newsletter",
		all: "/newsletter/*",
		list: "/list",
		add: "/add",
		edit: "/edit",
	},

	blog: {
		base: "/blog",
		all: "/blog/*",
		post: {
			base: "/blog/post",
			all: "/blog/post/*",
			list: "/list",
			add: "/add",
			edit: "/edit",
		},
		category: {
			base: "/blog/category",
			all: "/blog/category/*",
			list: "/list",
			add: "/add",
			edit: "/edit",
		},
		tag: {
			base: "/blog/tag",
			all: "/blog/tag/*",
			list: "/list",
			add: "/add",
			edit: "/edit",
		},
	},
	media: {
		base: "/media",
		all: "/media/*",
		list: "/list",
		folder: {
			base: "/media/folder",
			all: "/media/folder/*",
			list: "/list",
			add: "/add",
			edit: "/edit",
		},
		gallery: {
			base: "/media/gallery",
			all: "/media/gallery/*",
			list: "/list",
			add: "/add",
			edit: "/edit",
			folderName: "/media/gallery/:folderName",
		},
	},
};

export const ROUTES_OBJECT = {};
for (const key in routes) {
	if (Object.hasOwnProperty.call(routes, key)) {
		const data = routes[key];
		ROUTES_OBJECT[data.name] = data;
	}
}

export default routes;
